:root {
    --green: #01E375;
    --green-lt: #30C952;
    --black: #000;
}
::-webkit-scrollbar {
    width: 7px;
}
::-webkit-scrollbar-thumb:hover {
    background: var(--green-lt);
}
::-webkit-scrollbar-thumb {
    background: rgb(18, 60, 8);
}
::-webkit-scrollbar-track {
    background: rgb(2, 12, 7);
}